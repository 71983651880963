<template>
    <div>
        <VisiteForm ref="VisiteForm"  :key="newVisiteModalKey" :visite="selectedVisite"             :visible="visiteModalVisible"
                    :processing="processing"
                    :demande="demande"
                    @create="handleCreate"
                    @cancel="closeModal"
                    @close="closeModal"/>
        <VisiteValidationForm ref="VisiteRapportModal"  :key="newVisiteValidationModalKey" :visite="selectedVisite"
                    :visible="visiteValidationModalVisible"
                    :processing="processing"
                    @create="handleVisiteValidationCreate"
                    @cancel="closeVisiteModal"
                    @close="closeVisiteModal"/>
        <a-row type="flex" justify="center" class="table_head">
            <a-col :xs="24" :sm="10" :md="20" class="mrgin_btm">
                <h1>Liste des visites</h1>
                <br>
                <a-button
                        class="editable-add-btn"
                        type="primary"
                        @click="createNewVisite"
                        v-if="!loading"
                >Nouvelle
                </a-button
                >
            </a-col>
            <a-col :xs="24"  :sm="24" :md="20">
                <ListeVisites :data-source="visites"
                              :is-admin="true"
                              :processing="processing"
                              @edit="onEdit($event)"
                              @delete="onDelete($event)"
                              @upload_success="onUploadSuccess($event)"
                              @delete_file="onFileDelete($event)"
                />
            </a-col>
        </a-row>
    </div>
</template>

<script>
    import ListeVisites from "@/components/common/demandes/ListeVisites";
    import VisiteForm from "./VisiteForm";
    import VisiteValidationForm from "./VisiteValidationForm";
    import {mapActions, mapState} from "vuex";


    export default {
        name: "VisitesIndex",
        components: {
            ListeVisites,
            VisiteForm,
            VisiteValidationForm
        },
        created() {
            this.id = this.$route.params["id"];
            this.fetchDemande();
            this.fetchData();
            this.genererateNewVisiteModalKey();
            this.genererateNewVisiteValidationModalKey();
        },
        data() {
            return {
                loading: false,
                visible: false,
                processing: false,
                visiteModalVisible: false,
                visiteValidationModalVisible: false,
                decisionFormProcessing: false,
                selectedVisite: null,
                demande : null,
                id: null,
                newVisiteModalKey: 0,
                newVisiteValidationModalKey: 0,
            };
        },

        computed: {
            ...mapState({
                user: (state) => state.adherents.selected_adherent,
                visites: (state) => state.visites.visites,
            }),
        },

        methods: {
            fetchDemande(){
                this.getDemande(this.id)
                    .then((response)=> this.demande = response.data)
            }
            ,
            handleCreate() {
                const  form = this.$refs.VisiteForm.form;
                let that = this;
                form.validateFields((err, values) => {
                    if (!err) {
                        this.startProcessing();
                        values['dt_reel_visite'] = this.formatDate( values['dt_reel_visite'], "YYYY-MM-DD") ;
                        values['demande_id'] = this.id;
                        if(this.selectedVisite){
                            this.update({ id : this.selectedVisite.id, ...values,exercice_type_reglement_id : this.get(this , 'demande.exercice_type_reglement.id')})
                                .then((response) => {
                                    if (response.status === this.HTTP_OK) {
                                        that.closeModal();
                                        form.resetFields();
                                        that.fetchData();
                                    }
                                })
                                .finally(() => that.stopProcessing());
                            return
                        }
                        this.add({  ...values, exercice_type_reglement_id : this.get(this , 'demande.exercice_type_reglement.id')})
                            .then((response) => {
                                if (response.status === this.HTTP_OK) {
                                    that.closeModal();
                                    form.resetFields();
                                    that.fetchData();
                                }

                            })
                            .finally(() => that.stopProcessing());
                    }
                });
            },
            handleVisiteValidationCreate() {
                const  form = this.$refs.VisiteRapportModal.form;
                let that = this;
                form.validateFields((err, values) => {
                    if (!err) {
                        this.startProcessing();
                        values['dt_reel_visite'] = this.formatDate( values['dt_reel_visite'], "YYYY-MM-DD") ;
                        if(this.selectedVisite){
                            this.updateVisiteRapport({ id : this.selectedVisite.id, ...values})
                                .then((response) => {
                                    if (response.status === this.HTTP_OK) {
                                        that.closeVisiteModal();
                                        form.resetFields();
                                        that.fetchData();
                                    }
                                })
                                .finally(() => that.stopProcessing());
                            return
                        }
                        this.add({  ...values})
                            .then((response) => {
                                if (response.status ===  this.HTTP_OK) {
                                    that.closeVisiteModal();
                                    form.resetFields();
                                    that.fetchData();
                                }

                            }).finally(() => that.stopProcessing());
                    }
                });
            },
            fetchData(q = '') {
                this.startProcessing();
               this.getVisites({'id': this.id})
                         .finally(() => this.stopProcessing())
                     
            }
            ,
            onDelete(id) {
                this.delete(id)
                    .then(() =>this.fetchData());
            },
            onEdit(id) {
                this.getDemande(this.id)
                    .then((response)=> this.demande = response.data)
                    .then(()=>{
                        this.edit(id)
                           .then((response) => {
                                    this.selectedVisite = response.data;
                                    this.showModal();
                                    }) 

                    });
           
            },
            onUploadSuccess(){
              this.fetchData();
            },  onFileDelete(id){
                this.stopProcessing();
                this.deleteVisiteFile(id)
                    .then(()=> this.fetchData())
                    .finally(()=> this.stopProcessing())  ;
            },
            startProcessing() {
                this.processing = true;
            }
            ,
            search(value) {
                this.fetchData(value)
            }
            ,
            closeModal() {
                this.selectedVisite = null
                this.visiteModalVisible = false;
            },
            closeVisiteModal() {
                this.selectedVisite = null
                this.visiteValidationModalVisible = false;
            },
            createNewVisite(){
                 this.getDemande(this.id)
                    .then(()=> this.showModal());
            },
            showModal() {
                this.genererateNewVisiteModalKey();
                this.visiteModalVisible = true;
            },
            showVisiteModal() {
                this.genererateNewVisiteValidationModalKey();
                this.visiteValidationModalVisible = true;
            },
            genererateNewVisiteModalKey() {
                this.newVisiteModalKey = Math.floor(Math.random() * 10 + 1);
            },
            genererateNewVisiteValidationModalKey() {
                this.newVisiteValidationModalKey = Math.floor(Math.random() * 10 + 1);
            },
            stopProcessing() {
                this.processing = false
            },
            ...mapActions({
                getVisites                 :  "fetchVisitesOfDemandeById",
                getDemande                 :  "fetchDemandeById",
                add                        :  "storeVisite",
                edit                       : "fetchVisiteById",
                updateVisiteRapport        : "updateVisiteRapport",
                deleteVisiteFile           : "deleteVisiteFile",
                update                     :  "updateVisite",
                delete                     :  "destroyVisite",
            }),
        }
    }
</script>

<style scoped>
.mrgin_btm{
    margin-bottom: 2%;
}
</style>