var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VisiteForm", {
        key: _vm.newVisiteModalKey,
        ref: "VisiteForm",
        attrs: {
          visite: _vm.selectedVisite,
          visible: _vm.visiteModalVisible,
          processing: _vm.processing,
          demande: _vm.demande
        },
        on: {
          create: _vm.handleCreate,
          cancel: _vm.closeModal,
          close: _vm.closeModal
        }
      }),
      _c("VisiteValidationForm", {
        key: _vm.newVisiteValidationModalKey,
        ref: "VisiteRapportModal",
        attrs: {
          visite: _vm.selectedVisite,
          visible: _vm.visiteValidationModalVisible,
          processing: _vm.processing
        },
        on: {
          create: _vm.handleVisiteValidationCreate,
          cancel: _vm.closeVisiteModal,
          close: _vm.closeVisiteModal
        }
      }),
      _c(
        "a-row",
        {
          staticClass: "table_head",
          attrs: { type: "flex", justify: "center" }
        },
        [
          _c(
            "a-col",
            { staticClass: "mrgin_btm", attrs: { xs: 24, sm: 10, md: 20 } },
            [
              _c("h1", [_vm._v("Liste des visites")]),
              _c("br"),
              !_vm.loading
                ? _c(
                    "a-button",
                    {
                      staticClass: "editable-add-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.createNewVisite }
                    },
                    [_vm._v("Nouvelle\n            ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 20 } },
            [
              _c("ListeVisites", {
                attrs: {
                  "data-source": _vm.visites,
                  "is-admin": true,
                  processing: _vm.processing
                },
                on: {
                  edit: function($event) {
                    return _vm.onEdit($event)
                  },
                  delete: function($event) {
                    return _vm.onDelete($event)
                  },
                  upload_success: function($event) {
                    return _vm.onUploadSuccess($event)
                  },
                  delete_file: function($event) {
                    return _vm.onFileDelete($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }